var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"project--partner-header"},[_c('div',{staticClass:"d-flex justify-space-between align-items-end"},[_c('div',{staticClass:"col"},[(_vm.projectPartner.developer_picture_url)?_c('div',{staticClass:"wrapper--brand my-2"},[_c('img',{attrs:{"src":_vm.projectPartner.developer_picture_url,"alt":_vm.projectPartner.developer_name}})]):_vm._e(),_c('div',{staticClass:"wrapper--info"},[_c('h1',{staticClass:"info--name"},[_vm._v(_vm._s(_vm.projectPartner.project_name))]),_c('h2',{staticClass:"info--desc"},[_vm._v(" "+_vm._s(_vm.projectPartner.project_address)+" ")])])]),_c('div',{staticClass:"col-auto"},[_c('share',{attrs:{"url":_vm.$router.currentRoute.path}})],1)]),_c('div',{staticClass:"section--gallery"},[_c('client-only',[_c('vue-gallery-slideshow',{attrs:{"images":_vm.galleryImages,"index":_vm.imageIndex},on:{"close":function($event){_vm.imageIndex = null}}})],1),_c('v-tabs',[(_vm.projectPartner.pano_embed_url)?_c('v-tab',{attrs:{"href":`#tab-1`}},[_vm._v(_vm._s(_vm.$t(`general.panorama`)))]):_vm._e(),(
          _vm.projectPartner.primary_photo ||
          (_vm.projectPartner.list_photos && _vm.projectPartner.list_photos.length > 0)
        )?_c('v-tab',{attrs:{"href":`#tab-2`}},[_vm._v(_vm._s(_vm.$t(`general.gallery`)))]):_vm._e(),(_vm.projectPartner.pano_embed_url)?_c('v-tab-item',{attrs:{"value":'tab-1'}},[_c('iframe',{staticClass:"display--photos",attrs:{"src":_vm.projectPartner.pano_embed_url,"allowfullscreen":"allowfullscreen","loading":"lazy"}})]):_vm._e(),(
          _vm.projectPartner.primary_photo ||
          (_vm.projectPartner.list_photos && _vm.projectPartner.list_photos.length > 0)
        )?_c('v-tab-item',{attrs:{"value":'tab-2'}},[_c('div',{on:{"click":_vm.showImage}},[_c('img',{staticClass:"display--photos",attrs:{"src":_vm.projectPartner.primary_photo,"alt":_vm.projectPartner.project_name}}),_c('button',{staticClass:"btn btn-primary--outline open--gallery",on:{"click":_vm.showImage}},[_c('i',{staticClass:"ion-images"}),(_vm.projectPartner.list_photos !== null)?_c('span',[_vm._v(_vm._s(_vm.$t('general.view1'))+" "+_vm._s(_vm.projectPartner.list_photos.length)+" "+_vm._s(_vm.$t('general.view2')))]):_vm._e()])])]):_vm._e()],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }